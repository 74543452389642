import React from 'react'
import internLottie from "../assets/internship.json";
import Lottie from 'lottie-react';
import Reviews from '../components/Reviews';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';
import heroLine from "../assets/hero-lines-mobile.svg";
import { motion } from 'framer-motion';

const Interns = () => {
    const navigate = useNavigate();
    const handleViewButton = () => {
        navigate("/intern/js");
    }
    const handleViewButton1 = () => {
        navigate("/intern/summer");
    }

    const gradientStyle2 = {
        background: 'linear-gradient(117.95deg, rgba(31, 162, 255, 1), rgba(18, 216, 250, 1), rgba(166, 255, 203, 1))',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent'
    };
    return (
        <>
            <div className='w-11/12 max-w-maxContent lg:mt-5 mt-3 mx-auto text-white flex flex-col items-center md:gap-0 gap-10 relative'>
                <motion.div initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                    viewport={{ once: false }} className='flex flex-col gap-1 items-center font-Rubik z-10'>
                    <p className='text-[2rem] md:text-[5rem] md:font-bold'>Enroll in our upcoming</p>
                    <p className='text-[3rem] md:text-[5rem] font-bold font-Nabla' style={gradientStyle2}>INTERNSHIP</p>
                    <section className='md:w-[70%]'>
                        <Lottie animationData={internLottie} loop={true} />
                    </section>
                </motion.div>
                <motion.div initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                    viewport={{ once: false }} className='absolute top-[10rem] md:top-0 z-0'>
                    <img src={heroLine} alt="Heroline" />
                </motion.div>

                <motion.div initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.4 }}
                    viewport={{ once: false }} className='flex md:flex-row flex-col gap-3 bg-[#7247E5] border-[1px] border-richblack-100 p-5 rounded-lg items-center my-4 hover:scale-105 transition-all transition-200 font-Rubik'>
                    <motion.div initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8 }}
                        viewport={{ once: false }} className=''>
                        <img src="https://res.cloudinary.com/df78ltwsn/image/upload/v1721038921/RemoteIntern/dqza81fbrpkpvwhqomcj.jpg" alt="thumbnail" width="300px" className='rounded-lg' />
                    </motion.div>
                    <motion.div initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.4 }}
                        viewport={{ once: false }} className='p-4 flex flex-col gap-1 text-[17px]'>
                        <h1 className='text-[1.5rem]'>JavaScript Internship</h1>
                        <p>(HTML, CSS, JS)</p>
                        <p>Build 6+ projects</p>
                        <div className='flex gap-2'>
                            <div className='flex flex-col border-r-2 border-richblack-200 p-2'>
                                <p>10K+</p>
                                <p className='font-inter font-light'>Registered Intern</p>
                            </div>
                            <div className='flex flex-col p-2'>
                                <p>4.8</p>
                                <p>Average Rating</p>
                            </div>
                        </div>
                        <button onClick={handleViewButton} className='bg-[#0E0A19] py-3 rounded-lg mt-1'>View Internship</button>
                    </motion.div>
                </motion.div>

                <motion.div initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.4 }}
                    viewport={{ once: false }} className='flex md:flex-row flex-col gap-3 bg-[#7247E5] border-[1px] border-richblack-100 p-5 rounded-lg items-center my-4 hover:scale-105 transition-all transition-200 font-NotoSans'>
                    <motion.div initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8 }}
                        viewport={{ once: false }} className=''>
                        <img src="https://res.cloudinary.com/df78ltwsn/image/upload/v1721038918/RemoteIntern/kttwjae2vpwrjdxfaeq0.jpg" alt="summer" width="300px" className='rounded-lg' />
                    </motion.div>
                    <motion.div initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.4 }}
                        viewport={{ once: false }} className='p-4 flex flex-col gap-1 text-[17px]'>
                        <h1 className='text-[1.5rem]'>Winter Internship</h1>
                        <p>(MongoDB, Express, React, Next, Node)</p>
                        <p>Build FullStack projects</p>
                        <div className='flex gap-2'>
                            <div className='flex flex-col border-r-2 border-richblack-200 p-2'>
                                <p>0K+</p>
                                <p className='font-inter font-light'>Registered Intern</p>
                            </div>
                            <div className='flex flex-col p-2'>
                                <p>0.0</p>
                                <p>Average Rating</p>
                            </div>
                        </div>
                        <button onClick={handleViewButton1} className='bg-[#0E0A19] py-3 rounded-lg mt-1'>Upcoming...</button>
                    </motion.div>
                </motion.div>

                <motion.div initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                    viewport={{ once: false }} className='w-11/12 mx-auto max-w-maxContent flex-col items-center justify-between  first-letter text-white mb-7 lg:mt-5 font-Rubik'>
                    <h2 className='text-center text-4xl font-bold text-white'>Review from Our past <span className='font-bold font-Nabla' style={gradientStyle2}>INTERNS</span></h2>
                    <Reviews />
                </motion.div>
            </div>
            <motion.div initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                viewport={{ once: false }} className='w-full bg-[#4a2b90] mt-[120px] md:mt-16 flex flex-col justify-center items-center font-Rubik'>
                <Footer />
            </motion.div>
        </>
    )
}

export default Interns;