import React from 'react'

const HighlightText = ({ text }) => {
    const gradientStyle = {
        background: 'linear-gradient(117.95deg, rgba(31, 162, 255, 1), rgba(18, 216, 250, 1), rgba(166, 255, 203, 1))',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent'
    };

    return (
        <span className='font-bold font-Rubik'
            style={gradientStyle}
        >
            {" "}
            {text}
        </span>
    )
}

export default HighlightText
