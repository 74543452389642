import React from 'react'
import { Link } from "react-router-dom"

const CTAButton = ({ children, active, linkto }) => {
    return (
        <Link to={linkto}>

            <div className='rounded-xl border-2 border-dashed border-white bg-[#4a2b90] px-6 py-3 font-semibold uppercase text-white transition-all duration-300 hover:translate-x-[-4px] hover:translate-y-[-4px] hover:rounded-2xl hover:shadow-[#4a2b90] active:translate-x-[0px] active:translate-y-[0px] active:rounded-2xl active:shadow-none'>
                {children}
            </div>

        </Link>
    )
}

export default CTAButton;