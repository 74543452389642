import React from 'react'
import Qutoe from '../components/Qutoe';
import Reviews from '../components/Reviews';
import StatsComponent from '../components/StatsComponent';
import Lottie from "lottie-react";
import mylottie from '../assets/myanimate.json'
import myMission from '../assets/mission.json'
import Footer from '../components/Footer';

import { motion } from 'framer-motion';

const About = () => {
    const gradientStyle1 = {
        background: 'linear-gradient(117.95deg, rgba(230, 92, 0, 1), rgba(249, 212, 35, 1))',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent'
    };
    const gradientStyle2 = {
        background: 'linear-gradient(117.95deg, rgba(31, 162, 255, 1), rgba(18, 216, 250, 1), rgba(166, 255, 203, 1))',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent'
    };
    return (
        <>
            <section>
                <motion.div initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                    viewport={{ once: false }} className='w-11/12 max-w-maxContent sm:mt-5 xs:mt-4 mt-3 lg:mt-[90px] mx-auto'>
                    <Qutoe />
                </motion.div>
            </section>

            <section>
                <div className='flex flex-col w-11/12 max-w-maxContent mx-auto mt-[5rem] font-Rubik'>

                    {/* Founding story bottom box */}
                    <motion.div initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8 }}
                        viewport={{ once: false }} className='flex lg:flex-row flex-col w-[90%] lg:w-[80%] justify-between mb-7 gap-3 mt-3 lg:m-auto lg:items-center lg:px-0 px-2'>
                        <div className='lg:w-[37%] flex flex-col gap-2 '>
                            <h1 className='text-[36px] font-semibold font-Spice' style={gradientStyle1}>Our Vision</h1>
                            <p className='text-[16px] text-richblack-300 font-medium'>With this vision in mind, we embarked on a journey to create an online platform that would revolutionize professional internships. Our team of dedicated experts worked tirelessly to develop a robust and intuitive platform that combines cutting-edge technology with real-world experience, fostering a dynamic and interactive environment for professional growth and skill development.</p>
                        </div>
                        <section className='lg:w-[45%]'>
                            <Lottie animationData={mylottie} loop={true} />
                        </section>

                    </motion.div>
                    <motion.div initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8 }}
                        viewport={{ once: false }} className='flex lg:flex-row-reverse flex-col w-[90%] lg:w-[80%] justify-between mb-7 gap-3 mt-3 lg:m-auto lg:items-center lg:px-0 px-2'>
                        <div className='lg:w-[37%] flex flex-col gap-2'>
                            <h1 className='text-[36px] font-semibold font-Spice' style={gradientStyle2}>Our Mission</h1>
                            <p className='text-[16px] text-richblack-300 font-medium'>Our mission goes beyond just offering internships online. We aim to create a vibrant community of aspiring professionals, where individuals can connect, collaborate, and learn from one another. We believe that industry knowledge thrives in an environment of sharing and dialogue, and we foster this spirit of collaboration through interactive projects, live mentorship sessions, and networking opportunities.</p>
                        </div>
                        <section className='lg:w-[37%]'>
                            <Lottie animationData={myMission} loop={true} />
                        </section>
                    </motion.div>
                </div>
            </section>

            <motion.div initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                viewport={{ once: false }} className='my-4'>
                <StatsComponent />
            </motion.div>

            <motion.div initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                viewport={{ once: false }} className='w-11/12 mx-auto max-w-maxContent flex-col items-center justify-between  first-letter text-white mb-7 font-NotoSans'>
                <h2 className='text-center text-4xl font-bold text-white'>Review from Our past <span className='font-bold font-Nabla' style={gradientStyle2}>INTERNS</span></h2>
                <Reviews />
            </motion.div>

            <motion.div initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                viewport={{ once: false }} className='w-full bg-[#4a2b90] mt-[120px] md:mt-16 flex flex-col justify-center items-center font-Rubik'>
                <Footer />
            </motion.div>


        </>
    )
}

export default About;