import Lottie from 'lottie-react';
import React from 'react'
import { motion } from 'framer-motion';

const InfoCard = ({ title, title2, image, video }) => {
    const gradientStyle = {
        background: 'linear-gradient(117.95deg, rgba(31, 162, 255, 1), rgba(18, 216, 250, 1), rgba(166, 255, 203, 1))',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent'
    };
    return (
        <motion.div initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: false }} className='w-full h-fit-content rounded-lg flex flex-col justify-around items-center bg-[#382452] bg-clip-padding  text-white p-4'>
            <div className="p-4 rounded-full md:w-[70%]">
                {/* <img src={image} alt="InfoImage" style={{ borderRadius: "30px" }} /> */}
                <Lottie animationData={video} loop={true} />
            </div>
            <div className='font-Rubik'>
                <h3 className='text-center text-[1.5rem] capitalize font-bold sm:text-xl'>{title}</h3>
                <p className='text-center text-[2rem] font-extrabold' style={gradientStyle}>{title2}</p>
            </div>
        </motion.div>
    )
}

export default InfoCard