import React from "react"
import ReactStars from "react-rating-stars-component"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"
import { motion } from "framer-motion"

// Import Swiper styles
import "swiper/css"
import "swiper/css/free-mode"
import "swiper/css/pagination"
// Icons
import { FaStar } from "react-icons/fa"
// Import required modules
import { Autoplay, FreeMode, Pagination } from "swiper/modules"
import reviews from "../data/reviews"

const Reviews = () => {
    return (
        <div className="text-white mb-5">
            <motion.div initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                viewport={{ once: false }} className="my-[50px] h-[184px] max-w-maxContentTab md:max-w-maxContent hidden md:block">
                <Swiper
                    slidesPerView={3}
                    spaceBetween={25}
                    loop={true}
                    freeMode={true}
                    autoplay={{
                        delay: 4500,
                        disableOnInteraction: false,
                    }}
                    modules={[FreeMode, Pagination, Autoplay]}
                    className="w-full"
                >
                    {reviews.map((review) => {
                        return (
                            <SwiperSlide key={review.id}>
                                <div className="flex flex-col gap-6 bg-[#1b1228] p-3 text-[14px] text-[#fffeff] rounded-lg">
                                    <div className="flex sm:flex-row flex-col items-center gap-2 ">
                                        <h3 className="font-semibold text-yellow-100">
                                            {review.rating}
                                        </h3>
                                        <ReactStars
                                            count={5}
                                            value={review.rating}
                                            size={20}
                                            edit={false}
                                            activeColor="#ffd700"
                                            emptyIcon={<FaStar />}
                                            fullIcon={<FaStar />}
                                        />
                                    </div>
                                    <p className="font-medium text-richblack-25">
                                        {review?.review}
                                    </p>

                                    <div className="flex sm:flex-row flex-col items-center gap-4">
                                        <img
                                            src={review.image}
                                            alt="images"
                                            className="h-9 w-9 rounded-full object-cover"
                                        />
                                        <div className="flex flex-col">
                                            <h1 className="font-semibold text-richblack-5">{review.name}</h1>
                                            <h2 className="text-[15px] font-medium text-richblack-500">
                                                {review.position}
                                            </h2>
                                        </div>
                                    </div>

                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </motion.div>

            <motion.div initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                viewport={{ once: false }} className="my-[50px] h-[184px] max-w-maxContentTab md:max-w-maxContent block md:hidden">
                <Swiper
                    slidesPerView={1}
                    spaceBetween={25}
                    loop={true}
                    freeMode={true}
                    autoplay={{
                        delay: 4500,
                        disableOnInteraction: false,
                    }}
                    modules={[FreeMode, Pagination, Autoplay]}
                    className="w-full"
                >
                    {reviews.map((review) => {
                        return (
                            <SwiperSlide key={review.id}>
                                <div className="flex flex-col gap-6 bg-[#1b1228] p-3 text-[14px] text-[#fffeff] rounded-lg">
                                    <div className="flex sm:flex-row flex-col items-center gap-2 ">
                                        <h3 className="font-semibold text-yellow-100">
                                            {review.rating}
                                        </h3>
                                        <ReactStars
                                            count={5}
                                            value={review.rating}
                                            size={20}
                                            edit={false}
                                            activeColor="#ffd700"
                                            emptyIcon={<FaStar />}
                                            fullIcon={<FaStar />}
                                        />
                                    </div>
                                    <p className="font-medium text-richblack-25">
                                        {review?.review}
                                    </p>

                                    <div className="flex sm:flex-row flex-col items-center gap-4">
                                        <img
                                            src={review.image}
                                            alt="images"
                                            className="h-9 w-9 rounded-full object-cover"
                                        />
                                        <div className="flex flex-col">
                                            <h1 className="font-semibold text-richblack-5">{review.name}</h1>
                                            <h2 className="text-[15px] font-medium text-richblack-500">
                                                {review.position}
                                            </h2>
                                        </div>
                                    </div>

                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </motion.div>
        </div>
    )
}

export default Reviews;