const reviews = [
    {
        "id": 1,
        "rating": 5,
        "review": "I recently completed an online internship through this website, and it was an exceptional learning experience! The quality of the projects was on par with industry standards, and the professionalism. ",
        "image": "https://api.dicebear.com/5.x/initials/svg?seed=$Raju%20$Bhai",
        "name": "Raju Bhai",
        "position": "Intern"
    },
    {
        "id": 2,
        "rating": 5,
        "review": "This online internship program is a fantastic stepping stone to a successful career. The projects are challenging and mirror what you would encounter in a professional setting. The support and guidance from the mentors .",
        "image": "https://api.dicebear.com/5.x/initials/svg?seed=$Suresh%20$Singh",
        "name": "Suresh Singh",
        "position": "Intern"
    },
    {
        "id": 3,
        "rating": 4,
        "review": "The internship was a great experience overall. The projects were industry-relevant, and the mentors were very helpful. It would have been perfect if there were more networking opportunities, but I still learned a lot.",
        "image": "https://api.dicebear.com/5.x/initials/svg?seed=$Arjun%20$Aggraval",
        "name": "Arjun Aggraval",
        "position": "Intern"
    },
    {
        "id": 4,
        "rating": 5,
        "review": "I gained invaluable skills and experience through this internship. The tasks were challenging, but the mentors provided excellent support and feedback. I feel more prepared for my future career thanks to this program.",
        "image": "https://api.dicebear.com/5.x/initials/svg?seed=$Sneha%20$Sharma",
        "name": "Sneha Sharma",
        "position": "Intern"
    },
    {
        "id": 5,
        "rating": 4,
        "review": "A very professional and well-organized internship. The projects were realistic and helped me understand what to expect in the industry. I appreciated the detailed feedback from mentors, which helped me improve my skills.",
        "image": "https://api.dicebear.com/5.x/initials/svg?seed=$Jyoti%20$Pathak",
        "name": "Jyoti Pathak",
        "position": "Intern"
    },
    {
        "id": 6,
        "rating": 5,
        "review": "This internship provided me with a solid foundation in my field. The real-world projects and professional guidance from mentors made it an enriching experience. I highly recommend it to anyone looking to gain practical experience.",
        "image": "https://api.dicebear.com/5.x/initials/svg?seed=$Gaurav%20$Chaudhari",
        "name": "Gaurav Chaudhari",
        "position": "Intern"
    },
    {
        "id": 7,
        "rating": 5,
        "review": "An excellent internship experience! The projects were directly relevant to my studies and career goals. The mentors were supportive and always available for guidance. It was a perfect blend of learning and professional development.",
        "image": "https://api.dicebear.com/5.x/initials/svg?seed=$Anjali%20$Rai",
        "name": "Anjali Rai",
        "position": "Intern"
    },
    {
        "id": 8,
        "rating": 4,
        "review": "I learned a lot during this internship. The projects were demanding but very educational. The mentors were experienced and provided constructive feedback. It was a great opportunity to apply .",
        "image": "https://api.dicebear.com/5.x/initials/svg?seed=$Ritesh%20$Singh",
        "name": "Ritesh Singh",
        "position": "Intern"
    },
    {
        "id": 9,
        "rating": 5,
        "review": "This online internship exceeded my expectations. The quality of the projects and the mentorship were top-notch. I feel much more confident in my skills and ready to enter the industry. Highly recommended!",
        "image": "https://api.dicebear.com/5.x/initials/svg?seed=$Saif%20$Ali",
        "name": "Saif Ali",
        "position": "Intern"
    },
    {
        "id": 10,
        "rating": 5,
        "review": "The best internship experience I've had so far! The projects were challenging and very relevant to the industry. The mentors were incredibly supportive and provided excellent feedback. This internship has significantly boosted my career prospects.",
        "image": "https://api.dicebear.com/5.x/initials/svg?seed=$Trisha%20$Mondal",
        "name": "Trisha Mondal",
        "position": "Intern"
    }
]

export default reviews;