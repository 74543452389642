import React, { useState } from 'react'
import { Link, matchPath, useLocation } from 'react-router-dom';
import Logo from "../assets/mylogo.png";
import { NavbarLinks } from '../data/navbar-links';
import { IoReorderThreeOutline } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";

const Navbar = () => {

    const [sideBarCheck, setSideBarCheck] = useState(false);
    const location = useLocation();
    const matchRoute = (route) => {
        return matchPath({ path: route }, location.pathname);
    }

    const handleSideBar = () => {
        setSideBarCheck(!sideBarCheck);
    }
    const handleNavSide = () => {
        setSideBarCheck(!sideBarCheck);
    }

    return (
        <div className='flex h-14 items-center bg-[#0E0A19] justify-center border-b-[1px] border-richblack-200'>
            <div className='flex w-11/12 max-w-maxContent items-center justify-between relative'>
                {/* Image added */}
                <Link to={"/"} className='flex gap-2 items-center'>
                    <img src={Logo} alt="RemoteIntern" loading='lazy' className='rounded-[50%]' width="40px" />
                    <p className='text-white text-[1.6rem] font-bold font-Rubik'>Remote<span className='text-[#7247E5]'>Intern</span></p>
                </Link>
                {/* Nav Links */}
                <nav className='hidden md:block'>
                    <ul className='flex gap-x-6 text-richblack-25'>
                        {
                            NavbarLinks.map((link, index) => {
                                return (
                                    <li key={index}>
                                        {
                                            <Link to={link?.path}>
                                                <p className={`${matchRoute(link?.path) ? "text-yellow-25" : "text-richblack-25"} font-Rubik`}>{link.title}</p>
                                            </Link>
                                        }
                                    </li>
                                )
                            })
                        }
                    </ul>
                </nav>

                {/* login signup dashboard button */}

                <button onClick={handleSideBar} className='block md:hidden text-white text-[40px]'>
                    {
                        sideBarCheck ? (<RxCross2 />) : (<IoReorderThreeOutline />)
                    }

                </button>

                <div className={`absolute top-12 right-0 bg-[#150f27] w-[300px] rounded-lg p-4 ${sideBarCheck ? 'visible' : 'hidden'} md:hidden z-50`}>

                    <ul className='flex flex-col text-richblack-25 items-center'>
                        {
                            NavbarLinks.map((link, index) => {
                                return (
                                    <li key={index} onClick={handleNavSide} className='py-5 hover:bg-[#7247E5] rounded-lg border border-[#7247E5] w-full m-2'>
                                        {
                                            <Link to={link?.path} className='flex items-center justify-center'>
                                                <p className={`${matchRoute(link?.path) ? "text-yellow-25" : "text-richblack-25 "} font-Rubik`}>{link.title}</p>
                                            </Link>
                                        }
                                    </li>
                                )
                            })

                        }

                    </ul>

                </div>


            </div>

        </div>
    )
}

export default Navbar;