import Lottie from 'lottie-react';
import React from 'react'
import { motion } from 'framer-motion';

const Summer = () => {
    return (
        <motion.div initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: false }} className='w-[100vw] h-[90vh] flex items-center justify-center text-white'>
            <p className='text-[1.5rem] md:text-[3.3rem] font-Spice'>Coming Soon...🙏</p>
        </motion.div>
    )
}

export default Summer;