

const FAQs = [
    {
        question: "1. What is RemoteIntern?",
        Answer: "RemoteIntern is an online platform that offers a variety of internships across different fields. Our goal is to provide valuable hands-on experience to students and professionals looking to enhance their skills and career prospects."
    },
    {
        question: "2. How do I apply for an internship?",
        Answer: `To apply for an internship, visit our "Internship" page, browse through the available opportunities, and click on the "Register" button for the internship that interests you. You will need to create an account and pay the registration fees to the respective amount to register in the internship.`
    },
    {
        question: "3. What types of internships do you offer?",
        Answer: "We offer internships in various fields such as marketing, software development, graphic design, data analysis, content writing, and more. Each internship has specific requirements and duration, which are mentioned in the internship description."
    },
    {
        question: "4. How are the internships conducted?",
        Answer: "All our internships are conducted online. You will be assigned tasks and projects to complete, and you work heavily on github as like in industry and you will communicate with your mentor and team through our platform and other online collaboration tools."
    },
    {
        question: `5. What is the "Perform well in the internship and win prizes and hoodies" feature?`,
        Answer: "This feature is designed to motivate and reward our interns for their hard work and dedication. Interns who perform exceptionally well during their internship have the chance to win exciting prizes, including hoodies, gift vouchers, and other goodies."
    },
    {
        question: "6. How do I qualify for the prizes and hoodies?",
        Answer: "To qualify for the prizes and hoodies, you need to consistently perform well throughout your internship. This includes meeting deadlines, producing high-quality work, showing initiative, and actively participating in team activities. Your mentor will assess your performance and nominate top performers."
    },
    {
        question: "7. Are the internships paid?",
        Answer: "Some of our internships are paid, while others may offer stipends or be unpaid. The details about compensation are mentioned in each internship description. We encourage you to apply for internships that best suit your needs and preferences."
    },
    {
        question: "8. How long do the internships last?",
        Answer: "The duration of each internship varies. Typically, internships can last anywhere from a few weeks to a few months. The specific duration is mentioned in the internship description."
    },
    {
        question: "9. How can I stay updated about new internship opportunities?",
        Answer: `You can stay updated by subscribing to our instagram, youtube social media platform, all are given in the footer section and regularly checking our "Internships" page for new listings.`
    },
    {
        question: "10. What support will I receive during my internship?",
        Answer: "During your internship, you will be assigned a mentor who will guide you and provide feedback on your work. You will also have access to various resources and tools on our platform to help you complete your tasks effectively."
    },
    {
        question: "11. Can I get a certificate after completing the internship?",
        Answer: "Yes, all interns who successfully complete their internship will receive a certificate of completion. This certificate can be a valuable addition to your resume and LinkedIn profile."
    },
    {
        question: "12. What if I have more questions or need help during my internship?",
        Answer: `If you have any questions or need assistance at any time, you can reach out to our support team through the "Contact Us" page on our website. We are always here to help you.`
    },
]

export default FAQs;