export const NavbarLinks = [
    {
        title: "Home",
        path: "/",
    },
    {
        title: "About",
        path: '/about',
    },
    {
        title: "Internship",
        path: "/intern",
    }
];