import { Route, Routes } from "react-router-dom"
import Navbar from "./components/Navbar"
import Home from "./pages/Home"
import About from "./pages/About"

import Interns from "./pages/Interns"


import InternJs from "./pages/InternJs"
import Summer from "./pages/Summer"
import Terms from "./pages/Terms"

function App() {

  return (
    <div className="w-screen min-h-screen bg-[#0E0A19] flex flex-col font-inter overflow-x-hidden">
      <Navbar />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/intern" element={<Interns />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/intern/js" element={<InternJs />} />
        <Route path="/intern/summer" element={<Summer />} />

      </Routes>
    </div>
  )
}

export default App

