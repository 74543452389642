import { Stars } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import React, { useEffect } from "react";
import {
    useMotionTemplate,
    useMotionValue,
    motion,
    animate,
} from "framer-motion";

const COLORS_TOP = ["#13FFAA", "#1E67C6", "#CE84CF", "#DD335C"];

const AuroraHero = () => {

    const gradientStyle = {
        background: 'linear-gradient(117.95deg, #833AB4, #FD1D1D, #FCB045)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent'
    };
    const color = useMotionValue(COLORS_TOP[0]);

    useEffect(() => {
        animate(color, COLORS_TOP, {
            ease: "easeInOut",
            duration: 10,
            repeat: Infinity,
            repeatType: "mirror",
        });
    }, []);

    const backgroundImage = useMotionTemplate`radial-gradient(125% 125% at 50% 0%, #020617 50%, ${color})`;

    return (
        <motion.section
            style={{
                backgroundImage,
            }}
            className="relative grid min-h-screen place-content-center overflow-hidden bg-gray-950 px-4 py-24 text-gray-200"
        >

            <motion.div initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                viewport={{ once: false }} className='text-white mx-auto flex flex-col items-center px-4 font-NotoSans relative z-10'>
                <p className='text-[2.5rem] md:text-[4rem] font-bold'>A Platform to showcase Your</p>
                <p className='text-[3rem] sm:text-[5rem] font-bold font-Spice' style={gradientStyle}>SKILLS</p>
                <p className='text-[1.2rem] md:text-[2rem]'>Place for enhancing you skills on real world projects.</p>
                <p className='text-[1.1rem] md:text-[2rem]'>Learn to build anything you can imagine.</p>

            </motion.div>


            <div className="absolute inset-0 z-0">
                <Canvas>
                    <Stars radius={50} count={2500} factor={4} fade speed={2} />
                </Canvas>
            </div>
        </motion.section>
    );
};

export default AuroraHero;