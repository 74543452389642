import React from 'react'
import Reviews from '../components/Reviews';
import Footer from '../components/Footer';
import { motion } from 'framer-motion';

const InternJs = () => {

    const gradientStyle2 = {
        background: 'linear-gradient(117.95deg, rgba(31, 162, 255, 1), rgba(18, 216, 250, 1), rgba(166, 255, 203, 1))',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent'
    };


    return (
        <>
            <div className='w-11/12 max-w-maxContent lg:mt-5 mt-3 mx-auto text-white flex flex-col'>

                <div className='flex md:flex-row flex-col md:justify-evenly mt-6 items-center font-Rubik'>
                    <motion.div initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8 }}
                        viewport={{ once: false }} className='md:w-[40%] flex flex-col md:p-4 sm:w-[56%] w-[69%]'>
                        <h1 className='text-[2.4rem] md:text-[3rem]'>JavaScript Internship</h1>
                        <p className='text-[19px] md:text-[25px]'>(HTML, CSS, JS)</p>
                        <p className='text-richblack-100 font-Rubik'>In this program, you are going to build 6+ project all of those are highly based on JavaScript. You are going to learning Beginner as well as Advance JavaScript concept. Most important all of the code are written by your himself. You work like a professional.</p>
                        <div className='flex gap-2 my-2 font-Ubuntu'>
                            <div className='flex flex-col border-r-2 border-richblack-200 p-2'>
                                <p>10K+</p>
                                <p className='font-light'>Registered Intern</p>
                            </div>
                            <div className='flex flex-col p-2'>
                                <p>4.8</p>
                                <p>Average Rating</p>
                            </div>
                        </div>
                    </motion.div>
                    <motion.div initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8 }}
                        viewport={{ once: false }} className='p-4 bg-[#7247E5] rounded-lg flex flex-col  h-fit my-4 font-Rubik'>
                        <div>
                            <img src="https://res.cloudinary.com/df78ltwsn/image/upload/v1721038921/RemoteIntern/dqza81fbrpkpvwhqomcj.jpg" alt="thumbnail" width="300px" className='rounded-lg' />
                        </div>
                        <div className='text-lg pb-2 pt-3'>
                            <p>Start : <span className='text-richblack-50'>10 October</span></p>
                            <p>End : <span className='text-richblack-50'>25 November</span></p>
                        </div>
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLScbDyoxK88n6pZLHm21o_Z3Z-cc760dCGxC7U1gES8cTDHotg/viewform?usp=sf_link" target='_blank'>
                            <button className='bg-[#0E0A19] py-3 rounded-lg mt-1 w-full text-[20px] font-NotoSans font-semibold'>Register</button>
                        </a>
                    </motion.div>
                </div>

                <motion.div initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                    viewport={{ once: false }} className='w-full flex justify-center mt-9'>
                    <motion.div initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8 }}
                        viewport={{ once: false }} className='bg-[#7247E5] border-[1px] border-richblack-100 flex flex-col gap-5 w-fit items-center my-4 md:px-28 px md:py-10 rounded-lg p-4 font-Rubik hover:scale-[1.01] transition-all transition-200'>
                        <h1 className='text-[2rem] md:text-[3rem] lg:text-[4rem] font-semibold'>What you Build | Learn</h1>
                        <ol className='text-[20px] text-richblack-50 md:text-[30px]'>
                            <li>1. Build 6+ project in 6 week.</li>
                            <li>2. Master JavaScript.</li>
                            <li>3. Master DOM Manipulation.</li>
                            <li>4. Master Event Handling.</li>
                            <li>5. You got FIGMA Design.</li>
                            <li>6. Make PR like professional.</li>
                        </ol>
                    </motion.div>
                </motion.div>

                <motion.div initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                    viewport={{ once: false }} className='w-full flex justify-center mt-9'>
                    <motion.div initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8 }}
                        viewport={{ once: false }} className='bg-[#7247E5] border-[1px] border-richblack-100 flex flex-col gap-5 w-fit items-center my-4 md:px-16 px md:py-10 rounded-lg p-4 font-Rubik hover:scale-[1.01] transition-all transition-200'>
                        <h1 className='text-[2rem] md:text-[3rem] lg:text-[4rem] font-semibold'>Topic | Concept You Used!</h1>
                        <ol className='text-[20px] text-richblack-100 md:text-[30px]'>
                            <li className='text-white'>1. DOM Manipulations:</li>
                            <pre className='text-[16px] sm:text[17px] md:text-[26px]'>  -Document Object Model</pre>
                            <pre className='text-[16px] sm:text[17px] md:text-[26px]'>  -Event Handling</pre>
                            <pre className='text-[16px] sm:text[17px] md:text-[26px]'>  -Manipulating DOM Elements</pre>

                            <li className='text-white'>2. Asynchronous JavaScript: </li>
                            <pre className='text-[16px] sm:text[17px] md:text-[26px]'>  -Callbacks</pre>
                            <pre className='text-[16px] sm:text[17px] md:text-[26px]'>  -Promises</pre>
                            <pre className='text-[16px] sm:text[17px] md:text-[26px]'>  -Async/Await</pre>

                            <li className='text-white'>3. ES6+ Features: </li>
                            <pre className='text-[16px] sm:text[17px] md:text-[26px]'>  -Arrow Functions</pre>
                            <pre className='text-[16px] sm:text[17px] md:text-[26px]'>  -Classes</pre>
                            <pre className='text-[16px] sm:text[17px] md:text-[26px]'>  -Spread and Rest Operators</pre>

                            <li className='text-white'>3. Error Handling and Debugging: </li>
                            <pre className='text-[16px] sm:text[17px] md:text-[26px]'>  -Handling Errors</pre>
                            <pre className='text-[16px] sm:text[17px] md:text-[26px]'>  -Debugging Techniques</pre>


                        </ol>

                    </motion.div>
                </motion.div>

                <motion.div initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                    viewport={{ once: false }} className='flex md:flex-row flex-col md:justify-evenly md:mx-9 md:px-6 items-center md:my-5 my-2 py-2'>
                    <motion.div initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8 }}
                        viewport={{ once: false }} className='sm:w-[55%] md:w-[50%] lg:w-[45%] text-[1.7rem] font-bold md:text-[2.4rem] lg:text-[3rem] font-Rubik'>
                        <p>Got a Signed <span style={gradientStyle2} className='font-Nabla'>Certificate </span> on successful Completion of the Internship.</p>
                    </motion.div>
                    <motion.div initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8 }}
                        viewport={{ once: false }}>
                        <img src="https://res.cloudinary.com/df78ltwsn/image/upload/v1721038727/RemoteIntern/t1dfy9qwbdk07onw0mey.jpg" alt="certificate" width="350px" className='rounded-lg' />
                    </motion.div>
                </motion.div>

                <motion.div initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                    viewport={{ once: false }} className='w-11/12 mx-auto max-w-maxContent flex-col items-center justify-between  first-letter text-white mb-7 mt md:mt-4 mt-2 font-Rubik'>
                    <h2 className='text-center text-4xl font-bold text-white'>Review from Our past <span className='font-bold font-Nabla' style={gradientStyle2}>INTERNS</span></h2>
                    <Reviews />
                </motion.div>

            </div>
            <motion.div initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                viewport={{ once: false }} className='w-full bg-[#4a2b90] mt-[120px] md:mt-16 flex flex-col justify-center items-center font-Rubik'>
                <Footer />
            </motion.div>
        </>
    )
}

export default InternJs;