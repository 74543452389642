import React from 'react'
import { motion } from 'framer-motion'

const stats = [
    { count: "5K", label: "Active Interns" },
    { count: "10+", label: "Mentors" },
    { count: "50+", label: "Internships" },
    { count: "50+", label: "Awards" }
]

const StatsComponent = () => {
    return (
        <section className='w-[100vw] bg-richblack-700 my-3 font-Rubik'>
            <motion.div initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                viewport={{ once: false }}>
                <div className='flex lg:flex-row md:flex-row flex-col gap-6 w-11/12 mx-auto justify-around'>
                    {
                        stats.map((data, index) => {
                            return (
                                <motion.div initial={{ opacity: 0, y: 50 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.6 }}
                                    viewport={{ once: false, amount: 0.8 }} className='flex flex-col items-center px-5 py-1 my-6'>
                                    <h1 className='text-[30px] text-richblack-5 font-bold'>{data.count}</h1>
                                    <h2 className='text-richblack-500 font-semibold'>{data.label}</h2>
                                </motion.div>
                            )
                        })
                    }
                </div>
            </motion.div>
        </section>
    )
}

export default StatsComponent;