import React from 'react'
import { Link } from 'react-router-dom';
import CodeBlocks from '../components/CodeBlocks';
import HighlightText from '../components/HighlightText';
import InfoCard from '../components/InfoCard';
import infoCards from '../data/infoCard';
import Reviews from '../components/Reviews';
import Footer from '../components/Footer';
import AuroraHero from '../components/AuroraHero';
import { motion } from 'framer-motion';


const Home = () => {
    const gradientStyle2 = {
        background: 'linear-gradient(117.95deg, rgba(31, 162, 255, 1), rgba(18, 216, 250, 1), rgba(166, 255, 203, 1))',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent'
    };
    return (
        <main className='flex min-h-screen h-fit flex-col items-center justify-center  bg-[#000101]'>
            <motion.div initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                viewport={{ once: false }} id="home" className="flex flex-col lg:flex-row w-full h-fit max-w-7xl items-center justify-between lg:justify-center p-8 overflow-x-hidden relative overflow-y-hidden">
                <div className='w-full h-2/4 md:h-full lg:w-2/5 flex flex-col items-center justify-center lg:items-start gap-3 text-white z-10 md:my-0 my-12 sm:my-8'>

                    <div className='flex flex-col text-[4rem] sm:text-[5rem] font-extrabold  text-[#8b67e9] font-Rubik'>
                        <p>CODE,</p>
                        <p className='font-Spice'>BUI-LD,</p>
                        <p>LEA-RNT.</p>
                    </div>
                    <div className='flex flex-col gap-1  text-[1.3rem] sm:text-[2rem] font-bold font-Rubik'>
                        <p>Perform well in <span className='font-extrabold' style={gradientStyle2}>Internship</span></p>
                        <p>win  <span className='font-extrabold' style={gradientStyle2}>Perks and Prizes</span></p>
                    </div>
                    <div className='flex sm:flex-row flex-col gap-4 sm:gap-4'>
                        <Link to={"/intern"}>
                            <button className='rounded-xl border-2 border-dashed border-white bg-[#4a2b90] px-6 py-3 font-semibold uppercase text-white transition-all duration-300 hover:translate-x-[-4px] hover:translate-y-[-4px] hover:rounded-2xl hover:shadow-[#4a2b90] active:translate-x-[0px] active:translate-y-[0px] active:rounded-2xl active:shadow-none'>EXPLORE</button>
                        </Link>
                        <Link to={"/terms"}>
                            <button className='rounded-xl border-2 border-dashed border-white bg-[#4a2b90] px-6 py-3 font-semibold uppercase text-white transition-all duration-300 hover:translate-x-[-4px] hover:translate-y-[-4px] hover:rounded-2xl hover:shadow-[#4a2b90] active:translate-x-[0px] active:translate-y-[0px] active:rounded-2xl active:shadow-none'>FAQ's👌</button>
                        </Link>
                    </div>

                </div>

                <div className='w-full h-2/4 sm:h-full sm:w-[53%] lg:w-2/5 flex justify-center z-10 md:my-0 my-9 sm:my-7'>
                    <video src="https://res.cloudinary.com/df78ltwsn/video/upload/v1721038932/RemoteIntern/cvrq7ti4ptoxii6qpfye.mp4" autoPlay loop muted playsInline />
                </div>
                <div className='absolute z-10  md:top-12 top-[58%] left-4 md:left-auto animate-pulse'>
                    <img src="https://res.cloudinary.com/df78ltwsn/image/upload/v1721038874/RemoteIntern/ifi5jdmzzjdsj104vlly.png" alt="giff" width="100px" />
                </div>
                <div className='absolute z-10  bottom-5 right-8 md:right-14 md:left-auto animate-pulse'>
                    <img src="https://res.cloudinary.com/df78ltwsn/image/upload/v1721038879/RemoteIntern/pfjukeoqgieoep5edi4g.png" alt="giff" width="100px" />
                </div>
                <div className='absolute z-10  bottom-5 right-8 md:right-14 md:left-auto animate-spin opacity-10 '>
                    <img src="https://res.cloudinary.com/df78ltwsn/image/upload/v1721326815/RemoteIntern/ix5tmoxm1sbzklwyfuaj.png" alt="giff" width="100px" />
                </div>
                <div className='absolute z-10  md:top-16 top-[58%] left-4 md:left-auto animate-spin opacity-10'>
                    <img src="https://res.cloudinary.com/df78ltwsn/image/upload/v1721326791/RemoteIntern/pjyd9t2lcuupsaognteq.png" alt="giff" width="70px" />
                </div>

            </motion.div>

            <motion.div initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                viewport={{ once: false }} className=' mx-auto flex flex-col w-11/12 max-w-maxContent items-center 
                text-white justify-between z-10'>
                <CodeBlocks
                    position={"lg:flex-row"}
                    heading={
                        <div className='text-4xl font-semibold'>
                            <HighlightText text={" Start Building "} />
                            <br />
                            <HighlightText text={" in seconds"} />

                        </div>
                    }
                    subheading={
                        "Go ahead, make it possible, Build the project like Professional."
                    }
                    ctabtn1={
                        {
                            btnText: "Explore",
                            linkto: "/intern",
                            active: true,
                        }
                    }
                    ctabtn2={
                        {
                            btnText: "Learn",
                            linkto: "/login",
                            active: false,
                        }
                    }

                    codeblock={`<<!DOCTYPE html>\n<html>\nhead><title>Example</title>\n/head>\nbody/\nh1><ahref="/">Header</a>\n/h1/>\n<ahref="one/"></a><ahref="two/">\n<ahref="three/">Three</a>\nnav>`}
                    codeColor={"text-white"}
                />
            </motion.div>

            {/* Code Section 2 */}
            <motion.div initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                viewport={{ once: false }} className='relative mx-auto flex flex-col w-11/12 max-w-maxContent items-center 
              text-white justify-between z-10'>
                <CodeBlocks
                    position={"lg:flex-row-reverse"}
                    heading={
                        <div className='text-4xl font-semibold'>
                            <HighlightText text={" Use Your "} />
                            <br />
                            <HighlightText text={" Coding Skills"} />

                        </div>
                    }
                    subheading={
                        "Use your coding skills to solve the real world problems."
                    }
                    ctabtn1={
                        {
                            btnText: "Explore",
                            linkto: "/intern",
                            active: true,
                        }
                    }
                    ctabtn2={
                        {
                            btnText: "Learn",
                            linkto: "/signup",
                            active: false,
                        }
                    }

                    codeblock={`<<!DOCTYPE html>\n<html>\nhead><title>Example</title>\n/head>\nbody/\nh1><ahref="/">Header</a>\n/h1/>\n<ahref="one/"></a><ahref="two/">\n<ahref="three/">Three</a>\nnav>`}
                    codeColor={"text-yellow-25"}
                />
            </motion.div>
            <motion.div initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                viewport={{ once: false }} className='w-[100vw]'>
                <AuroraHero />
            </motion.div>

            <div className="h-fit min-h-screen w-full flex relative items-center justify-center p-9">
                <motion.div initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                    viewport={{ once: false }} className="w-full h-full flex items-center justify-center flex-col gap-9 max-w-7xl">
                    <h3 className='text-4xl md:text-5xl font-bold text-white font-Spice'>What You Get!</h3>
                    <div className="w-full grid grid-cols-1 grid-rows-3 md:grid-cols-2 md:grid-rows-2 lg:grid-cols-3 lg:grid-rows-1 gap-4 justify-between relative">
                        {infoCards.map((infoCard) => {
                            return (
                                <InfoCard key={infoCard.id} title={infoCard.title} title2={infoCard.title2} image={infoCard.image} video={infoCard.video} />
                            )
                        })}
                    </div>
                </motion.div>
            </div>

            <motion.div initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                viewport={{ once: false }} className='w-11/12 mx-auto max-w-maxContent flex-col items-center justify-between  first-letter text-white mb-7 font-NotoSans'>
                <h2 className='text-center text-4xl font-bold text-richblack-100 font-Rubik'>Review from Our past <span className='font-bold font-Nabla' style={gradientStyle2}>INTERNS</span></h2>
                <Reviews />
            </motion.div>

            <motion.div initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                viewport={{ once: false }} className='w-full bg-[#4a2b90] mt-[120px] md:mt-16 flex flex-col justify-center items-center font-Rubik'>
                <Footer />
            </motion.div>

        </main>
    )
}

export default Home;