
import myFigma from '../assets/figma.json'
import myGithub from '../assets/github.json'
import myTeam from '../assets/team.json'
const infoCards = [
    {
        title: "Got Design",
        title2: "FIGMA",
        video: myFigma,
        id: 1
    },
    {
        title: "Work on",
        title2: "GITHUB",
        video: myGithub,
        id: 2
    },
    {
        title: "Worked with",
        title2: "TEAM",
        video: myTeam,
        id: 3
    },
]

export default infoCards;