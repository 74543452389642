import React from "react";
import { Accordion, AccordionItem } from "@nextui-org/react";
import FAQs from "../data/FAQ.js";
import { motion } from "framer-motion";

const FAQ = () => {

    return (
        <motion.div initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: false }} className="my-5">
            <Accordion variant="light">
                {
                    FAQs.map((faq, index) => (
                        <AccordionItem key={index} aria-label={`Accordion ${index}`} title={faq.question} className="text-[1.2rem] text-[#cab6f8]">
                            <span className="text-richblack-100 py-2">
                                {faq.Answer}
                            </span>
                        </AccordionItem>
                    ))
                }

            </Accordion>
        </motion.div>
    );
}

export default FAQ;
