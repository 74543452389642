import React from 'react'
import FAQ from '../components/FAQ';
import { motion } from 'framer-motion';

const Terms = () => {
    return (
        <div className='w-11/12 max-w-maxContent mx-auto text-white'>
            <motion.div initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                viewport={{ once: false }} className='flex justify-center text-[1rem] md:text-[2.2rem] py-3 gap-2 font-Spice'>
                Frequently Asked Questions <span className='font-Nabla'> [FAQ]</span>
            </motion.div>
            <FAQ />
        </div>
    )
}

export default Terms;