import React from 'react'
import { RiInstagramFill } from "react-icons/ri";
import { AiFillYoutube } from "react-icons/ai";
import { FaGithub } from "react-icons/fa";
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className='flex flex-col gap-4 items-center justify-center text-white py-5 font-Rubik'>
            <p className='mt-7 font-bold text-[2rem] md:text-[4rem] '>RemoteIntern.in</p>
            <p className='mb-6 font-bold'>CODE, BUI-LD, LEARNT</p>
            <div className='flex flex-row gap-12 text-[2rem] md:text-[2.5rem]'>
                <button className='hover:text-caribbeangreen-300 transition-all hover:scale-95'>
                    <a href="https://www.instagram.com/remote_intern/" target='blank'><RiInstagramFill /></a>
                </button>

                <button className='hover:text-caribbeangreen-300 transition-all hover:scale-95'>
                    <a href="https://www.youtube.com/@RemoteIntern" target='blank'><AiFillYoutube /></a>
                </button>
                <button className='hover:text-caribbeangreen-300 transition-all hover:scale-95'>
                    <a href="https://github.com/remoteinterns" target='blank'><FaGithub /></a>
                </button>
            </div>
            <p className='text-richblack-100'>Copyright © 2024. All Rights Reversed</p>
        </div>
    )
}

// w-full bg-[#7247E5] mx-auto max-w-maxContent flex flex-col items-center justify-between  first-letter text-white mt-4

export default Footer;